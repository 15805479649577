define("ember-model-validator/decorators/object-validator", ["exports", "@ember/array", "@ember/object", "@glimmer/tracking", "ember-model-validator/decorators/core-validator"], function (_exports, _array, _object, _tracking, _coreValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  function objectValidator(Class) {
    var _class, _class2, _descriptor;
    let ObjectValidator = (0, _coreValidator.default)(_class = (_class2 = class ObjectValidator extends Class {
      constructor(...args) {
        super(...args);
        _initializerDefineProperty(this, "errors", _descriptor, this);
      }
      clearErrors() {
        (0, _object.set)(this, 'errors', {
          errorsFor: this.errors.errorsFor,
          _errors: (0, _array.A)()
        });
        (0, _object.set)(this, 'validationErrors', {});
        (0, _object.set)(this, 'isValidNow', true);
      }
      pushErrors(errors) {
        let errorsObj = {};
        for (let attribute in errors) {
          let messages = errors[attribute];
          if (!errorsObj[attribute]) {
            errorsObj[attribute] = (0, _array.A)([]);
          }
          errorsObj[attribute].push({
            message: messages.flat()
          });
          (0, _object.set)(this, 'errors', {
            ...this.errors,
            ...errorsObj,
            _errors: [...this.errors._errors, {
              attribute,
              message: messages.flat()
            }]
          });
        }
      }
      errorsFor(attribute) {
        return this.errors.errorsFor(attribute);
      }
      _modelRelations() {}
    }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "errors", [_tracking.tracked], {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: function () {
        return {
          _errors: (0, _array.A)(),
          errorsFor(attribute) {
            return (0, _array.A)(this._errors.filter(error => error.attribute === attribute));
          }
        };
      }
    })), _class2)) || _class;
    return ObjectValidator;
  }
  var _default = _exports.default = objectValidator;
});