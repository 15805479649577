define("ember-svg-jar/inlined/libraries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.a{fill:currentColor}</style></defs><path class=\"a\" d=\"M8.529 13.425H1.656c-.287 0-.5 0-.478-.4.03-.518.074-.606.512-.614.259 0 .517-.016.775 0 .327.022.426-.124.425-.437-.01-1.579 0-3.158 0-4.738 0-.432.011-.863 0-1.295-.008-.261.1-.346.348-.338.48.014.961.019 1.44 0 .306-.013.4.088.4.4-.012 1.948-.006 3.9-.006 5.848 0 .56 0 .56.572.56.443 0 .887-.015 1.33.005.333.015.449-.1.443-.444-.015-1.948-.007-3.9-.007-5.848 0-.512 0-.512.494-.512.431 0 .865.027 1.293-.008.391-.032.469.121.466.483-.016 1.949 0 3.9-.016 5.848 0 .377.115.5.485.483.455-.026.911-.005 1.367-.007.5 0 .5 0 .5-.486 0-1.961.009-3.924-.007-5.884 0-.344.1-.456.438-.435a10.3 10.3 0 001.292 0c.351-.022.467.087.464.452-.017 1.911-.008 3.824-.008 5.736 0 .617 0 .617.6.618h.401c.642 0 .77.135.677.762-.014.093-.141.2-.24.238a1.142 1.142 0 01-.366.012zM8.532 4.993H1.884c-.566 0-.636-.054-.7-.6-.022-.2-.1-.29-.3-.279a2.5 2.5 0 01-.258 0c-.246-.013-.543.079-.6-.309-.074-.485.011-.666.415-.814 2.586-.984 5.166-1.968 7.76-2.935a1.023 1.023 0 01.665 0c2.587.966 5.175 1.952 7.762 2.933a.632.632 0 01.386.916.479.479 0 01-.308.2 1.749 1.749 0 01-.478.008c-.228-.013-.331.074-.347.314-.034.492-.133.569-.653.569zM8.533 15.715H.553c-.475 0-.529-.057-.531-.529 0-.784.277-1.071 1.06-1.071h14.885c.836 0 1.206.436 1.053 1.276-.022.121-.2.235-.33.306-.086.047-.217.01-.328.01z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.09",
      "height": "15.721",
      "viewBox": "0 0 17.09 15.721"
    }
  };
});