define("ember-svg-jar/inlined/book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 11.25V.75a.748.748 0 00-.75-.75H3a3 3 0 00-3 3v10a3 3 0 003 3h10.25a.748.748 0 00.75-.75v-.5a.756.756 0 00-.278-.584 6.969 6.969 0 010-2.334.745.745 0 00.278-.582zM4 4.188A.188.188 0 014.188 4h6.625a.188.188 0 01.187.188v.625a.188.188 0 01-.187.187H4.188A.188.188 0 014 4.813zm0 2A.188.188 0 014.188 6h6.625a.188.188 0 01.187.188v.625a.188.188 0 01-.187.187H4.188A.188.188 0 014 6.813zM11.919 14H3a1 1 0 010-2h8.919a11.25 11.25 0 000 2z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16"
    }
  };
});