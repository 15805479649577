define("ember-svg-jar/inlined/diameter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M466.61 10.23c-3.73.39-15.33 1.77-25.54 2.95C299.8 29.29 167.77 112.6 86.82 236.97c-38.71 59.53-63.66 128.5-74.46 205.32-3.14 22.79-3.14 93.13 0 115.92 13.75 98.62 51.47 184.29 113.17 257.38l16.5 19.65-53.83 54.02c-48.33 48.73-53.83 54.82-53.83 60.52 0 8.64 7.46 16.11 16.11 16.11 5.7 0 11.79-5.5 60.51-53.84l54.03-53.83 19.65 16.5c73.09 61.69 158.75 99.42 257.38 113.17 23.18 3.14 93.13 3.14 116.9-.2 77.21-10.61 147.16-36.54 209.25-77.8 121.62-80.55 199.42-205.12 219.27-350.71 3.34-23.77 3.34-93.92.2-116.9-13.76-98.63-51.48-184.29-113.17-257.38L858 165.25l53.83-54.03c48.33-48.73 53.84-54.82 53.84-60.51 0-8.65-7.47-16.11-16.11-16.11-5.7 0-11.79 5.5-60.52 53.83l-54.03 53.83-19.65-16.5C743.25 64.66 657 26.54 560.92 12.98c-15.52-2.16-82.52-4.13-94.31-2.75zm100.2 35.17c88.22 12.97 172.7 52.07 239.31 110.42l8.25 7.46-325.75 325.76L162.86 814.8l-15.33-18.27c-43.62-52.07-75.64-115.13-92.54-181.15-10.61-42.04-13.56-67.2-13.56-115.13 0-30.85.98-48.73 3.54-65.82C77 221.83 241.84 64.65 455.81 42.65c24.95-2.75 84.28-1.18 111 2.75zm298.45 174.86c74.27 96.67 107.28 224.18 89.59 346.58-39.69 272.71-304.54 446.79-573.91 377.43-62.68-16.31-126.33-49.12-177.22-91.56l-18.27-15.33 325.76-325.74 325.76-325.76 7.47 8.25c3.93 4.72 13.35 16.31 20.82 26.13z\"/>",
    "attrs": {
      "class": "fill-current",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1000 1000"
    }
  };
});