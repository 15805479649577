define("ember-svg-jar/inlined/pqr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.84 18.262c-.541 0-.84-.338-.84-.924V1.078C0 .287.27.011 1.05.011h1.806C4.437.013 6.019.014 7.6 0c.344 0 .448.091.442.439-.02 1.4-.008 2.8-.008 4.2 0 .749.323 1.074 1.063 1.074 1.425 0 2.849.008 4.273-.007.3 0 .384.1.382.388v4.4h.017v5.052h-.02v1.9a.72.72 0 01-.806.814h-6.05l-6.053.002zm5.118-3.09l-.013-.808-1.5-3.149H2.332v3.954zm1.056 0l2.27-6.931H4.68zm1.023-.744l-.006.742h3.406v-3.954H9.569zM9.285 4.211q.012-1.945 0-3.89c0-.327.17-.368.4-.281a1.372 1.372 0 01.46.308Q11.779 1.97 13.4 3.6a1.67 1.67 0 01.258.318c.225.393.1.607-.338.608h-1.8c-.636 0-1.274-.008-1.908 0H9.6c-.225.004-.316-.073-.315-.315z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13.769",
      "height": "18.265",
      "viewBox": "0 0 13.769 18.265"
    }
  };
});