define("ember-svg-jar/inlined/forbidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M44.373 7.603c-10.137-10.137-26.633-10.137-36.77 0-10.138 10.138-10.138 26.632 0 36.77 5.068 5.068 11.727 7.604 18.385 7.604s13.316-2.535 18.385-7.604c10.137-10.138 10.137-26.633 0-36.77zM9.017 9.017c4.679-4.679 10.825-7.019 16.971-7.019a23.923 23.923 0 0116.228 6.347l-33.87 33.87c-8.653-9.403-8.447-24.08.671-33.198zm33.942 33.941c-9.119 9.119-23.795 9.325-33.199.671L43.63 9.76c8.654 9.403 8.448 24.08-.671 33.198z\"/>",
    "attrs": {
      "class": "fill-current",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 51.976 51.976"
    }
  };
});