define("ember-svg-jar/inlined/building", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.625 15H13V.75a.75.75 0 00-.75-.75H1.75A.75.75 0 001 .75V15H.375a.375.375 0 00-.375.375V16h14v-.625a.375.375 0 00-.375-.375zM4 2.375A.375.375 0 014.375 2h1.25A.375.375 0 016 2.375v1.25A.375.375 0 015.625 4h-1.25A.375.375 0 014 3.625zm0 3A.375.375 0 014.375 5h1.25A.375.375 0 016 5.375v1.25A.375.375 0 015.625 7h-1.25A.375.375 0 014 6.625zM5.625 10h-1.25A.375.375 0 014 9.625v-1.25A.375.375 0 014.375 8h1.25A.375.375 0 016 8.375v1.25a.375.375 0 01-.375.375zM8 15H6v-2.625A.375.375 0 016.375 12h1.25a.375.375 0 01.375.375zm2-5.375a.375.375 0 01-.375.375h-1.25A.375.375 0 018 9.625v-1.25A.375.375 0 018.375 8h1.25a.375.375 0 01.375.375zm0-3A.375.375 0 019.625 7h-1.25A.375.375 0 018 6.625v-1.25A.375.375 0 018.375 5h1.25a.375.375 0 01.375.375zm0-3A.375.375 0 019.625 4h-1.25A.375.375 0 018 3.625v-1.25A.375.375 0 018.375 2h1.25a.375.375 0 01.375.375z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16"
    }
  };
});