define("ember-svg-jar/inlined/open-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.02 0v2h16V0zm0 4v11.82A.179.179 0 00.2 16h15.62a.179.179 0 00.18-.18V4h-5.94v2.06H6V4H0z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.02",
      "height": "16",
      "viewBox": "0 0 16.02 16"
    }
  };
});