define("ember-svg-jar/inlined/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.a{fill:currentColor}</style></defs><g transform=\"translate(-6.562)\"><circle class=\"a\" cx=\"3.974\" cy=\"3.974\" r=\"3.974\" transform=\"translate(8.673)\"/><circle class=\"a\" cx=\"1.987\" cy=\"1.987\" r=\"1.987\" transform=\"translate(17.572 3.394)\"/><path class=\"a\" d=\"M21.543 11.596h-2.019a8.441 8.441 0 00-.128-.941 7.19 7.19 0 00-.244-.953 4.416 4.416 0 00-.4-.856 3.144 3.144 0 00-.569-.711 2.445 2.445 0 00-.79-.467l-.061-.025a1.17 1.17 0 01.267-.169 1.157 1.157 0 01.485-.1.473.473 0 01.186.108 1.336 1.336 0 00.117.078 1.546 1.546 0 01.149.1.751.751 0 00.273.108c.064.017.129.035.2.062a1.367 1.367 0 00.508.068h.13a1.523 1.523 0 00.537-.064c.069-.026.133-.044.19-.06a.75.75 0 00.268-.112l.046-.035.272-.208a.26.26 0 01.162-.051.523.523 0 01.057 0h.02a1.159 1.159 0 01.485.1 1.127 1.127 0 01.372.272 1.768 1.768 0 01.27.411 2.921 2.921 0 01.187.494 4.952 4.952 0 01.115.551c.031.2.051.385.061.553s.015.352.015.526a1.39 1.39 0 01-.317.962 1.085 1.085 0 01-.844.359zM18.731 12.666a2 2 0 01-.631 1.558 2.4 2.4 0 01-1.677.571H8.87a2.4 2.4 0 01-1.677-.571 2 2 0 01-.631-1.558q0-.436.03-.851a8.007 8.007 0 01.121-.9 6.731 6.731 0 01.229-.892 4.1 4.1 0 01.372-.8 2.925 2.925 0 01.536-.666 2.267 2.267 0 01.739-.44 2.718 2.718 0 01.963-.162 1.039 1.039 0 01.372.177c.19.118.3.146.528.292a4.515 4.515 0 00.94.279 3.7 3.7 0 001.136.111 4.113 4.113 0 001.2-.111 4.167 4.167 0 00.909-.279l.631-.395a.777.777 0 01.472-.074 2.715 2.715 0 01.964.164 2.273 2.273 0 01.739.44 2.909 2.909 0 01.536.666 4.134 4.134 0 01.372.8 6.683 6.683 0 01.229.892 8.007 8.007 0 01.121.9q.03.415.03.851z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.142",
      "height": "14.795",
      "viewBox": "0 0 16.142 14.795"
    }
  };
});