define("ember-svg-jar/inlined/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.14 1.144v13.71h-16V1.144zm0-1.143h-16A1.146 1.146 0 000 1.144v13.713A1.146 1.146 0 001.143 16h16a1.146 1.146 0 001.143-1.143V1.144A1.146 1.146 0 0017.143 0zm-2.286 4a1.714 1.714 0 11-1.714-1.714A1.714 1.714 0 0114.857 4zm1.143 9.714H2.285v-2.286l4-6.857 4.571 5.714h1.143l4-3.429z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18.286",
      "height": "16",
      "viewBox": "0 0 18.286 16"
    }
  };
});