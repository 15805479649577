define("ember-svg-jar/inlined/steelbeam", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><clipPath id=\"a\"><path d=\"M0 0h500v500H0z\"/></clipPath></defs><g clip-path=\"url(#a)\"><path d=\"M258.929 242.857H500L258.929 500H0l258.929-257.143z\" fill=\"#eee\"/><path d=\"M371.429 241.071V0L128.571 241.071V500l242.858-258.929z\" fill=\"#ddd\"/><path d=\"M258.929 0H500L258.929 242.857H0L258.929 0z\" fill=\"#eee\"/></g>",
    "attrs": {
      "style": "isolation:isolate",
      "viewBox": "0 0 500 500",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});