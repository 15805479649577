define("ember-svg-jar/inlined/book-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.233.001c-1.742.1-5.2.459-7.34 1.767a.489.489 0 00-.231.419V13.75a.5.5 0 00.74.429 19.384 19.384 0 016.951-1.491.991.991 0 00.954-.974V.977a1 1 0 00-1.073-.976zM8.414 1.768C6.277.46 2.814.1 1.074.001A1 1 0 000 .977v10.738a.991.991 0 00.954.974 19.382 19.382 0 016.953 1.492.5.5 0 00.738-.431V2.181a.478.478 0 00-.231-.413z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18.306",
      "height": "14.238",
      "viewBox": "0 0 18.306 14.238"
    }
  };
});