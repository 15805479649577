define("ember-scrollable/components/scroll-content-element", ["exports", "@ember/object", "@ember/runloop", "@ember/component", "ember-scrollable/templates/components/scroll-content-element", "ember-scrollable/util/css", "ember-scrollable/util/number", "@ember-decorators/component"], function (_exports, _object, _runloop, _component, _scrollContentElement, _css, _number, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   *
   * Handles scroll events within the body of the scroll content,
   * properly sets scrollTop / scrollLeft property depending on the configuration and the given scrollTo.
   *
   * @class ScrollContentElement
   * @extends Ember.Component
   */
  let ScrollContentElementComponent = _exports.default = (_dec = (0, _component2.layout)(_scrollContentElement.default), _dec2 = (0, _component2.tagName)(''), _dec3 = (0, _object.computed)('height', 'width'), _dec4 = (0, _object.computed)('stylesJSON.{height,width}'), _dec(_class = _dec2(_class = (_class2 = class ScrollContentElementComponent extends _component.default {
    constructor(...args) {
      super(...args);
      /**
       * Height of this content. Note content must have a height that is larger than this in order to cause overflow-y,
       * and enabling scrolling.
       *
       * @property height
       * @public
       * @type Number
       */
      _defineProperty(this, "height", null);
      /**
       * Width of this content. Note contnet must have a width that is larger than this in order to cause overflow-x
       * therefore enabling scrolling.
       *
       * @property width
       * @public
       * @type Number
       */
      _defineProperty(this, "width", null);
      /**
       * Integer representing desired scrollLeft to be set for this element.
       *
       * @property scrollToX
       * @public
       * @type Number
       * @default 0
       */
      _defineProperty(this, "scrollToX", 0);
      /**
       * Integer representing desired scrollTop to be set for this element.
       *
       * @property scrollToX
       * @public
       * @type Number
       * @default 0
       */
      _defineProperty(this, "scrollToY", 0);
      /**
       * Previous scrollToX value. Useful for calculating changes in scrollToX.
       *
       * @property previousScrollToX
       * @private
       * @type Number
       */
      _defineProperty(this, "previousScrollToX", 0);
      /**
       * Previous scrollToY value. Useful for calculating changes in scrollToY.
       *
       * @property previousScrollToY
       * @private
       * @type Number
       */
      _defineProperty(this, "previousScrollToY", 0);
    }
    /**
     * Callback function when scroll event occurs.
     * Arguments are: jQueryEvent, scrollOffset, and horizontal'|'vertical'
     * @property onScroll
     * @public
     * @type Function
     */
    onScroll() {}
    /**
     * Intermediate object to collect style attributes. Height and width are set dynamically such that space is allocated
     * for the given scrollbars that will be rendered.
     *
     * @property stylesJSON
     * @private
     */
    get stylesJSON() {
      const {
        height,
        width
      } = this;
      return {
        width: width + 'px',
        height: height + 'px'
      };
    }

    /**
     * String bound to the style attribute.
     *
     * @property style
     * @private
     * @type String
     */
    get style() {
      return (0, _css.styleify)(this.stylesJSON);
    }
    /**
     * Callback from scroll event on the content of this element.
     * Determines direction of scroll and calls the `onScroll` action with:
     *  - the jQueryEvent
     *  - the scrollOffset -- indicates positioning from top/left anchor point
     *  - 'horizontal' | 'vertical' -- indicates direction of scroll
     *
     * @method scrolled
     * @param e jQueryEvent
     */
    scrolled(e) {
      const newScrollLeft = e.target.scrollLeft;
      const newScrollTop = e.target.scrollTop;
      if (newScrollLeft !== this.previousScrollToX) {
        this.onScroll(e, newScrollLeft, 'horizontal');
        this.set(`previousScrollToX`, newScrollLeft);
      } else if (newScrollTop !== this.previousScrollToY) {
        this.onScroll(e, newScrollTop, 'vertical');
        this.set(`previousScrollToY`, newScrollTop);
      }
    }

    /**
     * Sets the scroll property (scrollTop, or scrollLeft) for the given the direction and offset.
     *
     * @method scrollToPosition
     * @private
     * @param offset  Number -- offset amount in pixels
     * @param direction String -- 'X' | 'Y' -- indicates what direction is being scrolled
     */
    scrollToPosition(offset, direction) {
      offset = _number.default.parseInt(offset, 10);
      if (_number.default.isNaN(offset)) {
        return;
      }
      let scrollOffsetAttr = direction === 'X' ? 'scrollLeft' : 'scrollTop';
      this.el[scrollOffsetAttr] = offset;
    }
    configureInitialScrollPosition() {
      this.scrollToPosition(this.scrollToX, 'X');
      this.scrollToPosition(this.scrollToY, 'Y');
    }
    elementInserted(element) {
      this.el = element;
      this.el.addEventListener('scroll', this.scrolled);
      this.configureInitialScrollPosition();
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.el?.removeEventListener('scroll', this.scrolled);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      // Sync property changes to `scrollToX` and `scrollToY` with the `scrollTop` and `scrollLeft` attributes
      // of the rendered DOM element.
      ['X', 'Y'].forEach(direction => {
        const oldOffset = this.get(`previousScrollTo${direction}`);
        const newOffset = this.get(`scrollTo${direction}`);
        if (oldOffset !== newOffset) {
          (0, _runloop.schedule)('afterRender', this, this.scrollToPosition, newOffset, direction);
        }
      });
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "stylesJSON", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "stylesJSON"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "style", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "style"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "scrolled", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "scrolled"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "scrollToPosition", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "scrollToPosition"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "configureInitialScrollPosition", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "configureInitialScrollPosition"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "elementInserted", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "elementInserted"), _class2.prototype)), _class2)) || _class) || _class);
});