define("ember-svg-jar/inlined/weld-cloud-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"first\" d=\"M11.255 11.771c-3.707-.65-7.074 1.45-8.955 4.482C-.83 21.3.154 28.927 10.531 30.747l25.603-.032c15.015.087 15.016-19.178.001-19.265.061-2.544-.38-4.782-2.249-6.808-2.468-2.676-3.364-3.94-9.775-3.94-7.185 0-9.45 3.48-12.355 6.497\"/><path class=\"second\" d=\"M11.255 11.771c-3.707-.65-7.074 1.45-8.955 4.482C-.83 21.3.154 28.927 10.531 30.747l25.603-.032c15.015.087 15.016-19.178.001-19.265.061-2.544-.38-4.782-2.249-6.808-2.468-2.676-3.364-3.94-9.775-3.94-7.185 0-9.45 3.48-12.355 6.497\"/>",
    "attrs": {
      "viewBox": "-10 -10 60 60",
      "stroke-width": "5",
      "fill": "none",
      "stroke": "#1f9fd5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});