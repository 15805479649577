define("ember-svg-jar/inlined/weldcloud-apps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.trolarol{fill:#fff}</style></defs><path class=\"trolarol\" d=\"M17.048 0h4.262v4.262h-4.262zM8.524 0h4.262v4.262H8.524zM0 0h4.262v4.262H0zM17.048 8.524h4.262v4.262h-4.262zM8.524 8.524h4.262v4.262H8.524zM0 8.524h4.262v4.262H0zM17.048 17.048h4.262v4.262h-4.262zM8.524 17.048h4.262v4.262H8.524zM0 17.048h4.262v4.262H0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21.31",
      "height": "21.31",
      "viewBox": "0 0 21.31 21.31"
    }
  };
});