define("ember-svg-jar/inlined/base-metal-qualifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><clipPath id=\"a\"><path d=\"M0 0h500v500H0z\"/></clipPath></defs><g clip-path=\"url(#a)\"><path d=\"M9 206.5h177l62 87H9v-87zM491 206.5H316.962L256 293.5h235v-87z\"/><path fill=\"#C8C6C6\" d=\"M229 293.5h46V309h-46z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "isolation:isolate",
      "viewBox": "0 0 500 500"
    }
  };
});