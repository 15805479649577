define("ember-svg-jar/inlined/thermometer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M236.896 38.375v12.854h-49.808V38.375h49.808zm-20.887 28.014h-28.921v12.854h28.921V66.389zm-28.921 40.868h49.808V94.404h-49.808v12.853zm28.921 15.168h-28.921v12.854h28.921v-12.854zm-28.921 40.868h49.808v-12.854h-49.808v12.854zm7.764 73.24c0 35.881-29.191 65.072-65.072 65.072s-65.072-29.191-65.072-65.072c0-23.252 12.211-44.384 32.134-56.074V32.938C96.843 14.775 111.618 0 129.78 0s32.938 14.775 32.938 32.938V180.46c19.923 11.677 32.134 32.815 32.134 56.073zm-12.854 0c0-19.73-10.964-37.565-28.619-46.53l-3.515-1.787V32.944c0-11.08-9.01-20.084-20.084-20.084-11.073 0-20.084 9.004-20.084 20.084v155.272l-3.515 1.787c-17.655 8.972-28.619 26.806-28.619 46.53 0 28.792 23.426 52.218 52.218 52.218s52.218-23.426 52.218-52.218zm-10.707 0c0 22.886-18.619 41.505-41.511 41.505-22.886 0-41.505-18.619-41.505-41.505 0-18.76 12.674-35.232 30.823-40.058l2.384-.636v-79.275h16.601v79.269l2.384.643c18.157 4.825 30.824 21.304 30.824 40.057zm-39.454-27.667c-.99-4.396-5.411-7.288-9.917-6.26-15.733 3.56-26.723 17.32-26.723 33.465 0 4.569 3.721 8.291 8.291 8.291s8.291-3.721 8.291-8.291c0-8.207 5.797-15.482 13.798-17.288 4.447-1.023 7.262-5.47 6.26-9.917z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 301.605 301.605",
      "class": "fill-current"
    }
  };
});