define("ember-svg-jar/inlined/wps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.841 18.262C.3 18.261 0 17.924 0 17.338V1.078C0 .287.27.011 1.05.011h1.806C4.437.013 6.019.014 7.6 0c.344 0 .448.091.442.439-.02 1.4-.008 2.8-.008 4.2 0 .749.324 1.074 1.063 1.074 1.424 0 2.848.008 4.273-.007.3 0 .384.1.382.388v1.871h.015v6.607h-.019v2.874a.72.72 0 01-.806.814H6.891l-6.05.002zm6.91-5.638l-.005 1.438h3.691v-5.53H9.284zM6.2 14.062L6.185 12.5l-1.5-3.964H2.332v5.53zm3.085-9.851q.012-1.945 0-3.89c0-.327.17-.368.4-.281a1.367 1.367 0 01.459.308Q11.779 1.97 13.4 3.6a1.671 1.671 0 01.258.318c.225.393.1.607-.338.608h-1.8c-.636 0-1.274-.008-1.908 0H9.6c-.225.004-.317-.073-.315-.315z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13.769",
      "height": "18.265",
      "viewBox": "0 0 13.769 18.265"
    }
  };
});