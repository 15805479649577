define("ember-model-validator/decorators/core-validator", ["exports", "@ember/object", "@ember/application", "@ember/string", "@ember/utils", "@ember/array", "ember-model-validator/postal-codes-regex", "ember-model-validator/messages/en", "ember-model-validator/messages/ar", "ember-model-validator/messages/fr", "ember-model-validator/messages/es", "ember-model-validator/messages/pt-br", "ember-model-validator/messages/uk", "ember-model-validator/messages/hu", "ember-model-validator/messages/sr", "ember-model-validator/messages/sr-cyrl"], function (_exports, _object, _application, _string, _utils, _array, _postalCodesRegex, _en, _ar, _fr, _es, _ptBr, _uk, _hu, _sr, _srCyrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const Messages = {
    en: _en.default,
    ar: _ar.default,
    fr: _fr.default,
    es: _es.default,
    'pt-br': _ptBr.default,
    uk: _uk.default,
    hu: _hu.default,
    sr: _sr.default,
    'sr-cyrl': _srCyrl.default
  };
  function coreValidator(constructor) {
    return class CoreValidator extends constructor {
      constructor(...args) {
        super(...args);
        _defineProperty(this, "validationErrors", {});
        _defineProperty(this, "isValidNow", true);
        _defineProperty(this, "addErrors", true);
        _defineProperty(this, "_validationMessages", {});
        let validatorLocale;
        if ((0, _application.getOwner)(this)) {
          validatorLocale = (0, _application.getOwner)(this)?.lookup('validator:locale');
        }
        this['_locale'] = this['_locale'] ?? (validatorLocale ? validatorLocale.locale : 'en');
        (0, _object.set)(this, '_validationMessages', Messages[this['_locale']]);
      }

      // to be implemented
      // clearErrors() {
      // }

      // to be implemented
      // pushErrors(_errors) {
      // }

      validate(options = {}) {
        let validations = this['validations'];

        // Clean all the current errors
        // Clean all the current errors
        this['clearErrors']();

        // Validate but not set errors
        if (Object.prototype.hasOwnProperty.call(options, 'addErrors')) {
          (0, _object.set)(this, 'addErrors', options['addErrors']);
        } else {
          (0, _object.set)(this, 'addErrors', true);
        }

        // Call validators defined on each property
        for (let property in validations) {
          for (let validation in validations[property]) {
            if (this._exceptOrOnly(property, validation, options)) {
              let validationName = (0, _string.capitalize)(validation);
              // allowBlank option
              if ((0, _object.get)(validations[property], `${validation}.allowBlank`) && (0, _utils.isEmpty)((0, _object.get)(this, property))) {
                continue;
              }
              // conditional functions
              let conditionalFunction = (0, _object.get)(validations[property], `${validation}.if`);
              if (conditionalFunction && !conditionalFunction(property, (0, _object.get)(this, property), this)) {
                continue;
              }
              this[`_validate${validationName}`](property, validations[property]);
            }
          }
        }

        // Check if it's valid or not
        if (!this.isValidNow) {
          let errors = this.validationErrors;

          // It may be invalid because of its relations
          if (this.addErrors && Object.keys(errors).length !== 0) {
            this['pushErrors'](errors);
          }
          return false;
        } else {
          return true;
        }
      }

      /**** Validators ****/
      _validateCustom(property, validation) {
        validation = (0, _array.isArray)(validation.custom) ? validation.custom : [validation.custom];
        for (let i = 0; i < validation.length; i++) {
          let customValidator = this._getCustomValidator(validation[i]);
          if (customValidator) {
            let passedCustomValidation = customValidator(property, (0, _object.get)(this, property), this);
            if (!passedCustomValidation) {
              (0, _object.set)(this, 'isValidNow', false);
              this._addToErrors(property, validation[i], this._validationMessages.customValidationMessage);
            }
          }
        }
      }
      _validatePresence(property, validation) {
        let propertyValue = (0, _object.get)(this, property);
        // If the property is an async relationship.
        if (this._modelRelations() && !(0, _utils.isBlank)(this._modelRelations()[property])) {
          propertyValue = (0, _object.get)(this, `${property}.content`);
        }
        if ((0, _utils.isBlank)(propertyValue)) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.presence, this._validationMessages.presenceMessage);
        }
      }
      _validateAbsence(property, validation) {
        if ((0, _utils.isPresent)((0, _object.get)(this, property))) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.absence, this._validationMessages.absenceMessage);
        }
      }
      _validateAcceptance(property, validation) {
        let propertyValue = (0, _object.get)(this, property),
          accept = validation.acceptance.accept || [1, '1', true];
        if (!this._includes((0, _array.A)(accept), propertyValue)) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.acceptance, this._validationMessages.acceptanceMessage);
        }
      }
      _validateFormat(property, validation) {
        let withRegexp = validation.format.with;
        if ((0, _object.get)(this, property) && String((0, _object.get)(this, property)).match(withRegexp) === null) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.format, this._validationMessages.formatMessage);
        }
      }
      _validateEmail(property, validation) {
        if (!(0, _object.get)(this, property) || String((0, _object.get)(this, property)).match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/) === null) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.email, this._validationMessages.mailMessage);
        }
      }
      _validateZipCode(property, validation) {
        const DEFAULT_COUNTRY_CODE = 'US';
        let propertyValue = (0, _object.get)(this, property);
        let countryCode = DEFAULT_COUNTRY_CODE;
        if (Object.prototype.hasOwnProperty.call(validation.zipCode, 'countryCode')) {
          countryCode = validation.zipCode.countryCode;
        }
        if ((0, _array.isArray)(countryCode)) {
          countryCode.forEach(function (code) {
            let postalCodeRegexp = _postalCodesRegex.default[code];
            if (typeof postalCodeRegexp === 'undefined') {
              postalCodeRegexp = _postalCodesRegex.default[DEFAULT_COUNTRY_CODE];
            }
            if (!propertyValue || String(propertyValue).match(postalCodeRegexp) === null) {
              (0, _object.set)(this, 'isValidNow', false);
              this._addToErrors(property, validation.zipCode, this._validationMessages.zipCodeMessage);
            }
          });
        } else {
          let postalCodeRegexp = _postalCodesRegex.default[countryCode];
          if (typeof postalCodeRegexp === 'undefined') {
            postalCodeRegexp = _postalCodesRegex.default[DEFAULT_COUNTRY_CODE];
          }
          if (!propertyValue || String(propertyValue).match(postalCodeRegexp) === null) {
            (0, _object.set)(this, 'isValidNow', false);
            this._addToErrors(property, validation.zipCode, this._validationMessages.zipCodeMessage);
          }
        }
      }
      _validateColor(property, validation) {
        let propertyValue = (0, _object.get)(this, property);
        if (!propertyValue || String(propertyValue).match(/([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i) === null) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.color, this._validationMessages.colorMessage);
        }
      }
      _validateURL(property, validation) {
        let propertyValue = (0, _object.get)(this, property);
        if (!propertyValue || String(propertyValue).match(/^((http|https):\/\/(\w+:{0,1}\w*@)?(\S+)|)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?$/) === null) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.URL, this._validationMessages.URLMessage);
        }
      }
      _validateSubdomain(property, validation) {
        let propertyValue = (0, _object.get)(this, property),
          reserved = validation.subdomain.reserved || [];
        if (!propertyValue || String(propertyValue).match(/^[a-z\d]+([-_][a-z\d]+)*$/i) === null || reserved.indexOf(propertyValue) !== -1) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.subdomain, this._validationMessages.subdomainMessage);
        }
      }
      _validateDate(property, validation) {
        let propertyValue = new Date((0, _object.get)(this, property));
        if (isNaN(propertyValue.getTime())) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.date, this._validationMessages.dateMessage);
          return;
        }
        if (Object.prototype.hasOwnProperty.call(validation.date, 'before') && validation.date.before) {
          if (propertyValue.getTime() >= new Date(validation.date.before).getTime()) {
            (0, _object.set)(this, 'isValidNow', false);
            let context = {
              date: new Date(validation.date.before)
            };
            validation.date.interpolatedValue = validation.date.before;
            this._addToErrors(property, validation.date, this._formatMessage(this._validationMessages.dateBeforeMessage, context));
          }
        }
        if (Object.prototype.hasOwnProperty.call(validation.date, 'after') && validation.date.after) {
          if (propertyValue.getTime() <= new Date(validation.date.after).getTime()) {
            (0, _object.set)(this, 'isValidNow', false);
            let context = {
              date: new Date(validation.date.after)
            };
            validation.date.interpolatedValue = validation.date.after;
            this._addToErrors(property, validation.date, this._formatMessage(this._validationMessages.dateAfterMessage, context));
          }
        }
      }
      _validateNumericality(property, validation) {
        let propertyValue = (0, _object.get)(this, property);
        if (!this._isNumber((0, _object.get)(this, property))) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation.numericality, this._validationMessages.numericalityMessage);
        }
        if (Object.prototype.hasOwnProperty.call(validation.numericality, 'onlyInteger') && validation.numericality.onlyInteger) {
          if (!/^[+-]?\d+$/.test(propertyValue)) {
            (0, _object.set)(this, 'isValidNow', false);
            this._addToErrors(property, validation.numericality, this._validationMessages.numericalityOnlyIntegerMessage);
          }
        }
        if (Object.prototype.hasOwnProperty.call(validation.numericality, 'even') && validation.numericality.even) {
          if (propertyValue % 2 !== 0) {
            (0, _object.set)(this, 'isValidNow', false);
            this._addToErrors(property, validation.numericality, this._validationMessages.numericalityEvenMessage);
          }
        }
        if (Object.prototype.hasOwnProperty.call(validation.numericality, 'odd') && validation.numericality.odd) {
          if (propertyValue % 2 === 0) {
            (0, _object.set)(this, 'isValidNow', false);
            this._addToErrors(property, validation.numericality, this._validationMessages.numericalityOddMessage);
          }
        }
        if (Object.prototype.hasOwnProperty.call(validation.numericality, 'greaterThan') && this._isNumber(validation.numericality.greaterThan)) {
          if (propertyValue <= validation.numericality.greaterThan) {
            (0, _object.set)(this, 'isValidNow', false);
            let context = {
              count: validation.numericality.greaterThan
            };
            validation.numericality.interpolatedValue = validation.numericality.greaterThan;
            this._addToErrors(property, validation.numericality, this._formatMessage(this._validationMessages.numericalityGreaterThanMessage, context));
          }
        }
        if (Object.prototype.hasOwnProperty.call(validation.numericality, 'greaterThanOrEqualTo') && this._isNumber(validation.numericality.greaterThanOrEqualTo)) {
          if (propertyValue < validation.numericality.greaterThanOrEqualTo) {
            (0, _object.set)(this, 'isValidNow', false);
            let context = {
              count: validation.numericality.greaterThanOrEqualTo
            };
            validation.numericality.interpolatedValue = validation.numericality.greaterThanOrEqualTo;
            this._addToErrors(property, validation.numericality, this._formatMessage(this._validationMessages.numericalityGreaterThanOrEqualToMessage, context));
          }
        }
        if (Object.prototype.hasOwnProperty.call(validation.numericality, 'equalTo') && this._isNumber(validation.numericality.equalTo)) {
          if (propertyValue !== validation.numericality.equalTo) {
            (0, _object.set)(this, 'isValidNow', false);
            let context = {
              count: validation.numericality.equalTo
            };
            validation.numericality.interpolatedValue = validation.numericality.equalTo;
            this._addToErrors(property, validation.numericality, this._formatMessage(this._validationMessages.numericalityEqualToMessage, context));
          }
        }
        if (Object.prototype.hasOwnProperty.call(validation.numericality, 'lessThan') && this._isNumber(validation.numericality.lessThan)) {
          if (propertyValue >= validation.numericality.lessThan) {
            (0, _object.set)(this, 'isValidNow', false);
            let context = {
              count: validation.numericality.lessThan
            };
            validation.numericality.interpolatedValue = validation.numericality.lessThan;
            this._addToErrors(property, validation.numericality, this._formatMessage(this._validationMessages.numericalityLessThanMessage, context));
          }
        }
        if (Object.prototype.hasOwnProperty.call(validation.numericality, 'lessThanOrEqualTo') && this._isNumber(validation.numericality.lessThanOrEqualTo)) {
          if (propertyValue > validation.numericality.lessThanOrEqualTo) {
            (0, _object.set)(this, 'isValidNow', false);
            let context = {
              count: validation.numericality.lessThanOrEqualTo
            };
            validation.numericality.interpolatedValue = validation.numericality.lessThanOrEqualTo;
            this._addToErrors(property, validation.numericality, this._formatMessage(this._validationMessages.numericalityLessThanOrEqualToMessage, context));
          }
        }
      }
      _validateExclusion(property, validation) {
        if (Object.prototype.hasOwnProperty.call(validation.exclusion, 'in')) {
          if (validation.exclusion.in.indexOf((0, _object.get)(this, property)) !== -1) {
            (0, _object.set)(this, 'isValidNow', false);
            this._addToErrors(property, validation.exclusion, this._validationMessages.exclusionMessage);
          }
        }
      }
      _validateInclusion(property, validation) {
        if (Object.prototype.hasOwnProperty.call(validation.inclusion, 'in')) {
          if (validation.inclusion.in.indexOf((0, _object.get)(this, property)) === -1) {
            (0, _object.set)(this, 'isValidNow', false);
            this._addToErrors(property, validation.inclusion, this._validationMessages.inclusionMessage);
          }
        }
      }
      _validateMatch(property, validation) {
        let matching = validation.match.attr || validation.match,
          propertyValue = (0, _object.get)(this, property),
          matchingValue = (0, _object.get)(this, matching);
        if (propertyValue !== matchingValue) {
          (0, _object.set)(this, 'isValidNow', false);
          let matchingUnCamelCase = this._unCamelCase(matching);
          let context = {
            match: matchingUnCamelCase
          };
          if ((0, _utils.typeOf)(validation.match) === 'object') {
            validation.match.interpolatedValue = matchingUnCamelCase;
          }
          this._addToErrors(property, validation.match, this._formatMessage(this._validationMessages.matchMessage, context));
        }
      }

      // Length Validator
      _validateLength(property, validation) {
        let propertyValue = (0, _object.get)(this, property),
          stringLength = !propertyValue ? 0 : String(propertyValue).length,
          validationType = (0, _utils.typeOf)(validation.length);
        if (validationType === 'number') {
          validation.length = {
            is: validation.length
          };
          this._exactLength(stringLength, property, validation);
        } else if (validationType === 'array') {
          validation.length = {
            minimum: validation.length[0],
            maximum: validation.length[1]
          };
          this._rangeLength(stringLength, property, validation);
        } else if (validationType === 'object') {
          if (Object.prototype.hasOwnProperty.call(validation.length, 'is')) {
            this._exactLength(stringLength, property, validation);
          } else {
            this._rangeLength(stringLength, property, validation);
          }
        }
      }
      _exactLength(stringLength, property, validation) {
        if (stringLength !== validation.length.is) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            count: validation.length.is
          };
          validation.length.interpolatedValue = validation.length.is;
          this._addToErrors(property, validation.length, this._formatMessage(this._validationMessages.wrongLengthMessage, context));
        }
      }
      _rangeLength(stringLength, property, validation) {
        let minimum = -1,
          maximum = Infinity;
        // Maximum and Minimum can be objects
        if ((0, _utils.typeOf)(validation.length.minimum) === 'number') {
          minimum = validation.length.minimum;
        } else if ((0, _utils.typeOf)(validation.length.minimum) === 'object' && Object.prototype.hasOwnProperty.call(validation.length.minimum, 'value')) {
          minimum = validation.length.minimum.value;
        }
        if ((0, _utils.typeOf)(validation.length.maximum) === 'number') {
          maximum = validation.length.maximum;
        } else if ((0, _utils.typeOf)(validation.length.maximum) === 'object' && Object.prototype.hasOwnProperty.call(validation.length.maximum, 'value')) {
          maximum = validation.length.maximum.value;
        }
        if (stringLength < minimum) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            count: minimum
          };
          if ((0, _utils.typeOf)(validation.length.minimum) === 'object') {
            validation.length.minimum.interpolatedValue = minimum;
          }
          this._addToErrors(property, validation.length.minimum, this._formatMessage(this._validationMessages.tooShortMessage, context));
        } else if (stringLength > maximum) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            count: maximum
          };
          if ((0, _utils.typeOf)(validation.length.maximum) === 'object') {
            validation.length.maximum.interpolatedValue = maximum;
          }
          this._addToErrors(property, validation.length.maximum, this._formatMessage(this._validationMessages.tooLongMessage, context));
        }
      }
      _validateRelations(property, validation) {
        if (validation.relations.indexOf('hasMany') !== -1) {
          if ((0, _object.get)(this, `${property}.content`)) {
            (0, _object.get)(this, `${property}.content`).forEach(objRelation => {
              if (!objRelation.validate()) {
                (0, _object.set)(this, 'isValidNow', false);
              }
            });
          }
        } else if (validation.relations.indexOf('belongsTo') !== -1) {
          if ((0, _object.get)(this, `${property}.content`) && !(0, _object.get)(this, `${property}.content`).validate()) {
            (0, _object.set)(this, 'isValidNow', false);
          }
        }
      }
      _validateMustContainCapital(property, validation) {
        let notContainCapital = String((0, _object.get)(this, property)).match(/(?=.*[A-Z])/) === null,
          message = validation.mustContainCapital.message || this._validationMessages.mustContainCapitalMessage;
        if (validation.mustContainCapital && notContainCapital) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation, message);
        }
      }
      _validateMustContainLower(property, validation) {
        let containsLower = String((0, _object.get)(this, property)).match(/(?=.*[a-z])/) !== null,
          message = validation.mustContainLower.message || this._validationMessages.mustContainLowerMessage;
        if (validation.mustContainLower && !containsLower) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation, message);
        }
      }
      _validateMustContainNumber(property, validation) {
        let containsNumber = String((0, _object.get)(this, property)).match(/(?=.*[0-9])/) !== null,
          message = validation.mustContainNumber.message || this._validationMessages.mustContainNumberMessage;
        if (validation.mustContainNumber && !containsNumber) {
          (0, _object.set)(this, 'isValidNow', false);
          this._addToErrors(property, validation, message);
        }
      }
      _validateMustContainSpecial(property, validation) {
        let regexString = validation.mustContainSpecial.acceptableChars || '-+_!@#$%^&*.,?()',
          regex = new RegExp(`(?=.*[${regexString}])`),
          containsSpecial = String((0, _object.get)(this, property)).match(regex) !== null,
          message = validation.mustContainSpecial.message || this._validationMessages.mustContainSpecialMessage;
        if (validation.mustContainSpecial && !containsSpecial) {
          (0, _object.set)(this, 'isValidNow', false);
          let context = {
            characters: regexString
          };
          this._addToErrors(property, validation, this._formatMessage(message, context));
        }
      }

      /**** Helper methods ****/
      _exceptOrOnly(property, validation, options) {
        let validateThis = true;
        if ((0, _utils.isPresent)(options.except) && (0, _array.isArray)(options.except)) {
          validateThis = !this._hasCompositeTag(property, validation, options.except);
        }
        if ((0, _utils.isPresent)(options.only) && (0, _array.isArray)(options.only)) {
          validateThis = this._hasCompositeTag(property, validation, options.only);
        }
        return validateThis;
      }
      _hasCompositeTag(property, validation, tags) {
        for (const tag of tags) {
          if (tag === property) return true;
          if (tag.indexOf(':') !== -1) {
            const [field, rest = ''] = tag.split(':', 2);
            if (field !== property) continue;
            const rules = rest.split(',');
            for (const rule of rules) {
              if (rule === validation) return true;
            }
          }
        }
        return false;
      }
      _getCustomValidator(validation) {
        let customValidator = validation;
        if ((0, _utils.typeOf)(validation) === 'object' && Object.prototype.hasOwnProperty.call(validation, 'validation')) {
          customValidator = validation.validation;
        }
        return this._isFunction(customValidator) ? customValidator : false;
      }
      _getCustomMessage(validationObj, defaultMessage, property) {
        if ((0, _utils.typeOf)(validationObj) === 'object' && Object.prototype.hasOwnProperty.call(validationObj, 'message')) {
          if (this._isFunction(validationObj.message)) {
            let msg = validationObj.message.call(this, property, (0, _object.get)(this, property), this);
            return this._isString(msg) ? msg : defaultMessage;
          } else {
            let context = {
              value: validationObj.interpolatedValue
            };
            return this._formatMessage(validationObj.message, context);
          }
        } else {
          return defaultMessage;
        }
      }
      _addToErrors(property, validation, defaultMessage) {
        let errors = this.validationErrors,
          message = this._getCustomMessage(validation, defaultMessage, property),
          errorAs = (0, _utils.typeOf)(validation) === 'object' ? validation.errorAs || property : property;
        if (!(0, _array.isArray)(errors[errorAs])) {
          errors[errorAs] = [];
        }
        if (this.addErrors) {
          errors[errorAs].push([message]);
        }
      }

      // Specific funcs
      _isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }
      _unCamelCase(str) {
        return str
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function (str) {
          return (0, _string.capitalize)(str);
        });
      }
      _isFunction(func) {
        return (0, _utils.isEqual)((0, _utils.typeOf)(func), 'function');
      }
      _isString(str) {
        return (0, _utils.isEqual)((0, _utils.typeOf)(str), 'string');
      }
      _includes(enums, value) {
        if (enums.includes) {
          return enums.includes(value);
        } else {
          // Support old ember versions
          return enums.contains(value);
        }
      }
      _modelRelations() {
        // eslint-disable-next-line ember/no-get
        if ((0, _object.get)(this, '_relationships')) {
          return this['_relationships'];
          // eslint-disable-next-line ember/no-get
        } else if ((0, _object.get)(this, '_internalModel._relationships')) {
          // eslint-disable-next-line ember/no-get
          return (0, _object.get)(this, '_internalModel._relationships.initializedRelationships');
          // eslint-disable-next-line ember/no-get
        } else if ((0, _object.get)(this, '_internalModel._recordData._relationships')) {
          // eslint-disable-next-line ember/no-get
          return (0, _object.get)(this, '_internalModel._recordData._relationships.initializedRelationships');
        } else {
          const relationships = {};
          if (this.constructor.eachRelationship) {
            this.constructor.eachRelationship(name => {
              relationships[name] = this['relationshipFor'](name);
            });
          }
          return relationships;
        }
      }
      _formatMessage(message, context = {}) {
        return message.replace(/\{(\w+)\}/, (s, attr) => context[attr]);
      }
    };
  }
  var _default = _exports.default = coreValidator;
});