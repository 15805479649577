define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.015 4.146L3.429 9.574v5.854a.571.571 0 00.572.572l4-.01a.571.571 0 00.569-.571v-3.42a.571.571 0 01.571-.571h2.286a.571.571 0 01.571.571v3.416a.571.571 0 00.571.573l4 .011a.571.571 0 00.571-.571V9.57l-6.579-5.424a.435.435 0 00-.546 0zm10.4 3.686l-2.984-2.457V.428a.429.429 0 00-.43-.428h-2a.429.429 0 00-.429.429v2.593l-3.2-2.631a1.714 1.714 0 00-2.179 0L.155 7.837a.429.429 0 00-.057.6l.911 1.109a.429.429 0 00.6.058l8.4-6.92a.435.435 0 01.546 0l8.4 6.92a.429.429 0 00.6-.057l.911-1.107a.429.429 0 00-.061-.6z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20.575",
      "height": "16",
      "viewBox": "0 0 20.575 16"
    }
  };
});