define("ember-svg-jar/inlined/cog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.121 9.923l-1.374-.792a6.214 6.214 0 000-2.265l1.374-.794a.39.39 0 00.177-.452 8.034 8.034 0 00-1.765-3.052.388.388 0 00-.477-.074l-1.374.794a6.088 6.088 0 00-1.961-1.132V.571a.386.386 0 00-.3-.377 8.109 8.109 0 00-3.523 0 .386.386 0 00-.3.377v1.588a6.28 6.28 0 00-1.967 1.132L2.26 2.497a.383.383 0 00-.477.074A7.986 7.986 0 00.018 5.623a.386.386 0 00.177.452l1.374.794a6.214 6.214 0 000 2.265l-1.374.797a.39.39 0 00-.177.452 8.034 8.034 0 001.764 3.048.388.388 0 00.477.074l1.374-.794a6.088 6.088 0 001.961 1.132v1.588a.386.386 0 00.3.377 8.109 8.109 0 003.523 0 .386.386 0 00.3-.377v-1.589a6.28 6.28 0 001.972-1.132l1.374.794a.383.383 0 00.474-.073 7.986 7.986 0 001.765-3.052.4.4 0 00-.181-.455zm-7.464.655a2.581 2.581 0 112.581-2.581 2.584 2.584 0 01-2.581 2.581z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15.317",
      "height": "16",
      "viewBox": "0 0 15.317 16"
    }
  };
});