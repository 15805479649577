define("ember-svg-jar/inlined/certificates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 1.091v12.777a1.067 1.067 0 001.067 1.067h15.468a1.067 1.067 0 001.065-1.067V1.093A1.093 1.093 0 0016.509 0H1.091A1.091 1.091 0 000 1.091zm2.451 2.091a.4.4 0 01.4-.4h11.9a.4.4 0 01.4.4v.441a.4.4 0 01-.4.4h-11.9a.4.4 0 01-.4-.4zM8.8 9.357a.4.4 0 01-.4.4H2.851a.4.4 0 01-.4-.4v-.441a.4.4 0 01.4-.4H8.4a.4.4 0 01.4.4zm0-2.8a.4.4 0 01-.4.4H2.851a.4.4 0 01-.4-.4v-.442a.4.4 0 01.4-.4H8.4a.4.4 0 01.4.4zm5.921 7.021l-1.347-1.043-1.4 1.028a.188.188 0 01-.281-.2l.667-3.218.7.923a.392.392 0 00.632-.009l.652-.92.681 3.224a.207.207 0 01-.312.215zm.613-5.761l.585.563a.16.16 0 01-.092.275l-.8.1a.161.161 0 00-.133.188l.143.8a.161.161 0 01-.237.169l-.709-.4a.16.16 0 00-.223.069l-.353.731a.161.161 0 01-.291 0l-.343-.735a.161.161 0 00-.221-.077l-.716.383a.161.161 0 01-.233-.173l.155-.8a.16.16 0 00-.133-.189l-.8-.112a.161.161 0 01-.087-.277l.593-.553a.161.161 0 000-.233l-.584-.563a.16.16 0 01.092-.275l.8-.1a.163.163 0 00.133-.188l-.143-.8a.161.161 0 01.236-.169l.709.4a.16.16 0 00.223-.069l.355-.731a.161.161 0 01.291 0l.343.735a.161.161 0 00.221.075l.716-.383a.161.161 0 01.233.173l-.155.8a.159.159 0 00.133.189l.8.112a.161.161 0 01.088.277l-.6.553a.161.161 0 000 .233z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.602",
      "height": "14.935",
      "viewBox": "0 0 17.602 14.935"
    }
  };
});