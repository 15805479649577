define("ember-svg-jar/inlined/notes-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"b\" x1=\"-3.017\" y1=\"-2.034\" x2=\"-2.034\" y2=\"-3.017\" gradientUnits=\"objectBoundingBox\"><stop offset=\"0\" stop-color=\"#0db3ca\"/><stop offset=\".75\" stop-color=\"#223189\"/><stop offset=\"1\" stop-color=\"#223189\"/></linearGradient><filter id=\"a\" x=\"0\" y=\"0\" width=\"39\" height=\"39\" filterUnits=\"userSpaceOnUse\"><feOffset dx=\"1\" dy=\"1\"/><feGaussianBlur result=\"c\"/><feFlood flood-opacity=\".569\"/><feComposite operator=\"in\" in2=\"c\"/><feComposite in=\"SourceGraphic\"/></filter></defs><g transform=\"translate(-.003 -.003)\" filter=\"url(#a)\"><path d=\"M0 0h28a10 10 0 0110 10v28H10A10 10 0 010 28V0z\" transform=\"rotate(90 19 19)\" fill=\"url(#b)\"/></g><g fill=\"#fff\"><path d=\"M22.652 29.587H5.458a3.288 3.288 0 013.289-3.289h13.788a3.491 3.491 0 003.491-3.491V9.019a3.288 3.288 0 013.288-3.289v17.2a6.661 6.661 0 01-6.662 6.657z\" opacity=\".5\"/><path d=\"M24.369 5.731v15.51a3.379 3.379 0 01-3.379 3.379H5.48z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "39",
      "height": "39",
      "viewBox": "0 0 39 39"
    }
  };
});