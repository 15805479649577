define("ember-svg-jar/inlined/arrow-collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><style>.cls-1{fill:#fff}</style></defs><g id=\"Layer_2\" data-name=\"Layer 2\"><g id=\"Layer_1-2\" data-name=\"Layer 1\"><g id=\"Group_43\" data-name=\"Group 43\"><circle id=\"Ellipse_7\" data-name=\"Ellipse 7\" class=\"cls-1\" cx=\"13\" cy=\"13\" r=\"13\"/><circle id=\"Ellipse_8\" data-name=\"Ellipse 8\" cx=\"13\" cy=\"13\" r=\"11\" fill=\"#164167\"/><path id=\"Subtract\" class=\"cls-1\" d=\"M16.59 18.57L8.53 13l8.06-5.57v3.23L13.2 13l3.39 2.34z\"/></g></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 26 26"
    }
  };
});