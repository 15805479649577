define("ember-svg-jar/inlined/welding-machine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M479.848 368H480V128h-48V96H272V16c0-8.824-7.176-16-16-16h-16c-8.824 0-16 7.176-16 16H80c0-8.824-7.176-16-16-16H48c-8.824 0-16 7.176-16 16v112H0v304h65.64c7.464 36.944 39.96 64 78.36 64s70.896-27.056 78.36-64h115.256c7.432 36.472 39.752 64 78.384 64 44.112 0 80-35.888 80-80 0-18.016-6.064-34.608-16.152-48zM240 16h16v112h-16V16zM80 32h144v16H80V32zm0 32h144v32H80V64zm0 48h144v16H80v-16zM48 16h16v112H48V16zm96 464c-29.592 0-54.744-20.104-61.888-48h123.776c-7.144 27.896-32.296 48-61.888 48zm128-64H16V144h256v272zm64 0h-48V144h16v-16h-32v-16h144v16h-96v16h144v208.152C450.608 342.064 434.024 336 416 336c-44.112 0-80 35.888-80 80zm80 64c-35.288 0-64-28.712-64-64s28.712-64 64-64 64 28.712 64 64-28.712 64-64 64z\"/><path d=\"M416 384c-17.648 0-32 14.352-32 32s14.352 32 32 32 32-14.352 32-32-14.352-32-32-32zm0 48c-8.824 0-16-7.176-16-16s7.176-16 16-16 16 7.176 16 16-7.176 16-16 16zM176 160H32v144h144V160zm-16 128H48V176h112v112zM112 320H32v64h80v-64zm-16 48H48v-32h48v32zM144 384h80v-64h-80v64zm16-48h48v32h-48v-32z\"/><path d=\"M128 240c13.232 0 24-10.768 24-24s-10.768-24-24-24-24 10.768-24 24 10.768 24 24 24zm0-32c4.408 0 8 3.584 8 8s-3.592 8-8 8-8-3.584-8-8 3.592-8 8-8zM64 192h16v16H64zM64 224h16v16H64zM64 256h16v16H64zM256 160h-64v48h64v-48zm-16 32h-32v-16h32v16zM256 224h-64v48h64v-48zm-16 32h-32v-16h32v16zM304 176h16v16h-16zM304 224h16v16h-16zM304 272h16v16h-16zM304 320h16v16h-16zM304 368h16v16h-16zM432 176h16v16h-16zM432 224h16v16h-16zM432 272h16v16h-16zM432 320h16v16h-16z\"/>",
    "attrs": {
      "class": "fill-current",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 496 496"
    }
  };
});