define("ember-svg-jar/inlined/down-chevron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M386.257 114.331L202.128 252.427 18 114.331l-18 24 202.128 151.596 202.129-151.596z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 404.257 404.257"
    }
  };
});